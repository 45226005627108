import React from 'react';
import ReactDOM from 'react-dom';
import Viz from 'wever-visualization-clients';
import "bootstrap/dist/css/bootstrap.min.css";
import 'wever-visualization-clients/dist/assets/style.css';

ReactDOM.render(
  <React.StrictMode>
    <Viz />
  </React.StrictMode>,
  document.getElementById('root')
);